<template>
  <Content>
    <template #contentBody>
      <a-row>
        <a-col :span="24">
          <a-steps :current="2" style="max-width: 1800px" size="small">
            <a-step :title="$t('logistics.consignment_create_order')" />
            <a-step :title="$t('logistics.add_goods')" />
            <a-step :title="$t('logistics.select_logistics')" />
            <a-step :title="$t('common.completed')" />
          </a-steps>
        </a-col>
      </a-row>
      <div class="mt-2 mb-3">
        <a-typography-text strong>{{
          $t("logistics.order_info")
        }}</a-typography-text>
      </div>
      <a-descriptions>
        <a-descriptions-item :label="$t('logistics.relation_order_no')">
          <CPlanNumberItem :no="cache.relationOrderNo"></CPlanNumberItem>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('logistics.source_of_sales')">{{
          cache.selectedSourceSales
        }}</a-descriptions-item>
        <a-descriptions-item
          :label="$t('logistics.recipients')"
          v-if="
            selectedAgentOrderLogisticsType ==
            agentOrderLogisticsTypeEnum.seLogistics
          "
        >
          {{ cache.recipients }} {{ cache.contactNumber }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('logistics.shipments_warehouse')">{{
          cache.consignmentWarehouseNo
        }}</a-descriptions-item>
        <a-descriptions-item
          :label="$t('logistics.receiving_address')"
          :span="2"
          v-if="
            selectedAgentOrderLogisticsType ==
            agentOrderLogisticsTypeEnum.seLogistics
          "
        >
          {{ getAddress(cache.addressData) }}
        </a-descriptions-item>
      </a-descriptions>

      <div>
        <a-table
          :columns="columns"
          :data-source="cache.producTableList"
          :scroll="{ x: 800 }"
          size="small"
          :pagination="false"
        >
          <template #serialNumber>
            <span>
              {{ $t("warehouse.serial_number") }}
            </span>
          </template>
          <template #productNameAndSeSku>
            <span>
              {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
            </span>
          </template>
          <template #packagingSize>
            <span>
              {{ $t("logistics.business_packaging_size") }}
            </span>
          </template>
          <template #weight>
            <span>
              {{ $t("logistics.weight") }}
            </span>
          </template>
          <template #amount>
            <span>
              {{ $t("logistics.count") }}
            </span>
          </template>

          <template #productNameAndSeSkuCustom="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.picture" />
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>

          <template #packagingSizeCustom="{ record }">
            <span v-if="!record.length || !record.width || !record.height"
              >-</span
            >
            <span v-else
              >{{ record.length }}x{{ record.width }}x{{
                record.height
              }}cm</span
            >
          </template>

          <template #amountCustom="{ record }">
            {{ record.amount }}Unit
          </template>
        </a-table>
      </div>

      <!-- 运输特征 -->
      <div class="mt-3">
        <span>{{ $t("warehouse.transport_characteristics") }}:</span>
        <a-tag
          color="default"
          class="ml-1"
          v-for="item in cache.transportCharacteristics"
          :key="item"
        >
          {{ $t($enumLangkey("transportCharacteristics", item)) }}
        </a-tag>
      </div>
      <!-- 额外包装材料选择 -->

      <template
        v-if="cache.packMaterialType === packMaterialTypeEnum.outPacking"
      >
        <div class="mt-3" v-if="cache.selectedPackagingType">
          <span
            >{{ $t("warehouse.additional_packing_material_selection") }}:</span
          >
          <a-card style="max-width: 500px">
            <div>
              {{ cache.selectedPackagingInfo.name }}-{{
                !cache.selectedPackagingInfo.length ||
                !cache.selectedPackagingInfo.width ||
                !cache.selectedPackagingInfo.width
                  ? ""
                  : cache.selectedPackagingInfo.length +
                    "x" +
                    cache.selectedPackagingInfo.width +
                    "x" +
                    cache.selectedPackagingInfo.height +
                    " cm"
              }}
            </div>
            <div>
              {{ $t("logistics.max_capacity") }}:
              {{ cache.selectedPackagingInfo.maxVolume }} m<sup>3</sup>
            </div>
            <div>
              {{ $t("warehouse.max_volume_weight") }}:
              {{ cache.selectedPackagingInfo.packBearingQuantity }} kg
            </div>
            <div>
              {{ $t("logistics.weight_packing_material") }}:
              {{ cache.selectedPackagingInfo.weight }} kg
            </div>
          </a-card>
        </div>
      </template>
      <template
        v-else-if="
          cache.packMaterialType === packMaterialTypeEnum.mergePackaging
        "
      >
        <div class="mt-3" v-if="cache.mergePackageData">
          <span
            >{{
              $t(
                $enumLangkey(
                  "packMaterialType",
                  packMaterialTypeEnum.mergePackaging
                )
              )
            }}:</span
          >
          <a-card style="max-width: 500px">
            <span>{{ $t("warehouse.merge_packaging_fee") }}: </span>
            <span
              >{{ cache.mergePackageData.currencySymbol
              }}{{
                $filters.amountToFixed2(
                  cache.mergePackageData.operateFee?.amount
                )
              }}</span
            >
          </a-card>
        </div>
      </template>

      <!-- 包装材料:缠膜 -->
      <template v-if="cache.packMaterialType === packMaterialTypeEnum.stretchFilmPackaging">
        <div class="mt-3" >
          <span>{{$t('warehouse.packing_methods')}}: {{ $t('warehouse.wrapping_film') }}</span>
        </div>
      </template>

      <!-- 选择物流方式 -->
      <div class="mt-4" v-if="selectedAgentOrderLogisticsType != agentOrderLogisticsTypeEnum.destruction">
        <span class="text-error">* </span
        ><strong>{{ $t("warehouse.logistics_way") }}: </strong>
        {{
          $t(
            $enumLangkey(
              "agentOrderLogisticsType",
              selectedAgentOrderLogisticsType
            )
          )
        }}
      </div>
      <div
        class="mt-2"
        v-if="
          selectedAgentOrderLogisticsType ==
          agentOrderLogisticsTypeEnum.seLogistics
        "
      >
        <a-button
          :loading="logisticsSchemeLoading"
          @click="handleAcquisitionLogistics"
          >{{ $t("warehouse.acquisition_logistics") }}</a-button
        >
      </div>

      <!-- 选择的赛易物流方式 -->
      <div
        v-if="
          selectedAgentOrderLogisticsType ==
          agentOrderLogisticsTypeEnum.seLogistics
        "
        class="mt-2 mb-4"
      >
        <!-- 物流方案 -->
        <div v-if="isSearchLogistics">
          <a-tabs
            @change="handleChangeTabs"
            v-model:activeKey="feeCalculationMethod"
          >
            <a-tab-pane
              :key="feeCalculationMethodEnum.template"
              v-if="
                !(selectedLogistics == feeCalculationMethodEnum.truckApi) &&
                ((list && list.length > 0) ||
                  !truckList ||
                  truckList.length == 0)
              "
              :forceRender="true"
              :tab="$t('logistics.logistics')"
            >
              <div>
                <a-table
                  :columns="columnsLogisticsScheme"
                  :data-source="list"
                  :scroll="{ x: true, y: 1000 }"
                  :pagination="false"
                  size="small"
                  :loading="logisticsSchemeLoading"
                  :locale="{
                    emptyText: $t(
                      'logistics.not_currently_available_logistics_scheme'
                    ),
                  }"
                >
                  <template #option v-if="!selectedLogisticsScheme"> </template>
                  <template #logisticsScheme>
                    <span>
                      {{ $t("warehouse.logistics_scheme") }} /
                      {{ $t("warehouse.logistics_code") }}
                    </span>
                  </template>

                  <template #logisticsWay>
                    <span>
                      {{ $t("warehouse.logistics_type") }}
                    </span>
                  </template>

                  <template #deliveryWay>
                    <span>
                      {{ $t("warehouse.delivery_way") }}
                    </span>
                  </template>

                  <template #referenceAging>
                    <span>
                      {{ $t("warehouse.reference_aging") }}
                    </span>
                  </template>
                  <template #freights>
                    <span>
                      {{ $t("warehouse.freight_estimate") }}
                    </span>
                  </template>

                  <template #details>
                    <span>
                      {{ $t("common.details") }}
                    </span>
                  </template>
                  <template #optionalServices v-if="selectedLogisticsScheme">
                    <span>
                      {{ $t("warehouse.optional_services") }}
                    </span>
                  </template>
                  <template #optionCustom="{ record }">
                    <a-button
                      :loading="record.selectedLogisticsLoaidng"
                      @click="hanldeGetFee(record)"
                      >{{ $t("common.select") }}</a-button
                    >
                  </template>
                  <template #logisticsSchemeCustom="{ record }">
                    <div class="text-warning">
                      {{ record.scheme }} / {{ record.code }}
                    </div>
                    <a-row :gutter="[8, 8]">
                      <a-col
                        v-for="item in record.descriptionItems"
                        :key="item"
                      >
                        <CheckCircleOutlined
                          class="text-warning mr-1"
                          :style="{ fontSize: '12px' }"
                        />
                        <span
                          style="font-size: 12px; color: rgba(102, 102, 102, 1)"
                        >
                          {{ item }}
                        </span>
                      </a-col>
                    </a-row>
                  </template>
                  <template #logisticsWayCustom="{ record }">
                    {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
                  </template>
                  <template #deliveryWayCustom="{ record }">
                    {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
                  </template>
                  <template #referenceAgingCustom="{ record }">
                    <div>
                      {{ record.daysMin }}-{{ record.daysMax }}
                      {{ $t("warehouse.workday") }}
                      ({{ $t($enumLangkey("aging", record.channelTimeType)) }})
                    </div>
                  </template>
                  <template #freightsCustom="{ record }">
                    <div class="text-warning">
                      {{ $t("warehouse.average_unit_price") }}:
                      {{ record.currencySymbol }}
                      {{ $filters.amountToFixed2(record.averagePrice) }}
                    </div>
                    <div class="text-warning">
                      {{ $t("warehouse.total_prices") }}:
                      {{ record.currencySymbol }}
                      {{ $filters.amountToFixed2(record.expressFee) }}
                    </div>
                  </template>
                  <template #detailsCustom="{ record }">
                    <div v-if="record.logisticsCalcMethod == 0">
                      {{ $t("logistics.chargeable_weight") }}:
                      {{ record.chargeableWeight }} kg
                    </div>
                    <div v-else>
                      {{ $t("logistics.chargeable_volume") }}:
                      {{ record.chargeableVolume }} m<sup>3</sup>
                    </div>

                    <div>
                      {{ $t("logistics.volume_weight_coefficient")
                      }}{{ record.volumeWeightParam }}
                    </div>
                  </template>
                  <template
                    #optionalServicesCustom="{ record }"
                  >
                    <div v-if="selectedLogisticsScheme">
                      <div v-if="record.logisticsRegionInfo.isDdpSupported">
                        <a-checkbox v-model:checked="record.isChooseDdp">
                          DDP ({{ record.currencySymbol }}
                          {{
                            $filters.amountToFixed2(
                              record.logisticsRegionInfo.ddpFee
                            )
                          }}
                          )
                        </a-checkbox>
                      </div>

                      <div v-if="record.logisticsRegionInfo.isSignSupported">
                        <CSignSupported
                          v-model:value="record.signatureType"
                          :symbol="record.currencySymbol"
                          :signFeeOptions="
                            record.logisticsRegionInfo.signFeeDtos
                          "
                          :type="'editable'"
                        />
                      </div>
                    </div>

                    <div v-else>
                      <div v-if="record.logisticsRegionInfo.isDdpSupported">
                        DDP: (
                        {{
                          $filters.formatCurrencyAmount(
                            record.currencySymbol,
                            record.logisticsRegionInfo.ddpFee,
                            false
                          )
                        }}
                        )
                      </div>

                      <div v-if="record.logisticsRegionInfo.isSignSupported">
                        <CSignSupported
                          :symbol="record.currencySymbol"
                          :signFeeOptions="
                            record.logisticsRegionInfo?.signFeeDtos
                          "
                          :type="'preview'"
                        />
                      </div>
                    </div>

                    <!-- 不支持ddp和签名签收 -->
                    <div
                      v-if="
                        !record.logisticsRegionInfo.isDdpSupported &&
                        !record.logisticsRegionInfo.isSignSupported
                      "
                    >
                      -
                    </div>
                  </template>
                </a-table>

                <div class="mt-2">
                  <a-tag color="warning" v-if="isRemoteArea">{{
                    $t("logistics.remote_site")
                  }}</a-tag>
                  <template v-if="isCommercial != null">
                    <a-tag color="warning" v-if="isCommercial">{{
                      $t("logistics.business_site")
                    }}</a-tag>
                    <a-tag color="warning" v-else>{{
                      $t("logistics.residence")
                    }}</a-tag>
                  </template>
                </div>

                <a-form-item class="mt-3" v-show="isShowVATInput">
                  <template #label>
                    <div>
                      <span style="color: red"> * </span>
                      <label>VAT</label>
                    </div>
                  </template>
                  <a-input style="max-width: 250px" v-model:value="VAT" />
                </a-form-item>
              </div>
            </a-tab-pane>

            <a-tab-pane
              :key="feeCalculationMethodEnum.truckApi"
              :tab="$t('logistics.truck')"
              v-if="
                !(selectedLogistics == feeCalculationMethodEnum.template) &&
                truckList &&
                truckList.length > 0
              "
              force-render
            >
              <a-table
                :columns="columnsTruckScheme"
                :data-source="truckList"
                :scroll="{ x: 1000, y: 1000 }"
                size="small"
                :pagination="false"
                :loading="logisticsSchemeLoading"
              >
                <template #quoteOptionsTitle>
                  <a-popover placement="top">
                    <template #content>
                      {{ $t("logistics.quote_options_tips") }}
                    </template>
                    <span>{{ $t("logistics.quote_options") }}</span>
                    <span class="ml-1">
                      <QuestionCircleOutlined />
                    </span>
                  </a-popover>
                </template>

                <template #logisticsSchemeCustom="{ record }">
                  <div class="text-warning">
                    {{ record.scheme }} / {{ record.code }}
                  </div>
                  <a-row :gutter="[8, 8]">
                    <a-col v-for="item in record.descriptionItems" :key="item">
                      <CheckCircleOutlined
                        class="text-warning mr-1"
                        :style="{ fontSize: '12px' }"
                      />
                      <span
                        style="font-size: 12px; color: rgba(102, 102, 102, 1)"
                      >
                        {{ item }}
                      </span>
                    </a-col>
                  </a-row>
                </template>

                <template #logisticsWayCustom="{ record }">
                  {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
                </template>
                <template #deliveryWayCustom="{ record }">
                  {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
                </template>

                <template #quoteOptions="{ record }">
                  <div v-if="!record.isSelected">
                    <a-form-item :label="$t('logistics.quote_type')">
                      <a-select
                        v-model:value="record.quoteModel.quoteTypeId"
                        style="max-width: 250px"
                        @change="handleChangeQuoteType(record.quoteModel)"
                        :loading="DropdownList.loading"
                      >
                        <a-select-option
                          v-for="(
                            item, index
                          ) in DropdownList?.quotationTypeList"
                          :key="index"
                          :value="item.id"
                          :option="item.needService"
                        >
                          {{ item.name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                    <a-form-item
                      :label="$t('logistics.service_level')"
                      v-if="record.quoteModel.needService"
                    >
                      <a-select
                        v-model:value="record.quoteModel.serviceLevel"
                        @change="record.quoteModel.trackFeeDetail = null"
                        style="max-width: 250px"
                        :loading="DropdownList.loading"
                      >
                        <a-select-option
                          v-for="(item, index) in DropdownList.serviceLevelList"
                          :key="index"
                          :value="item.id"
                          >{{ item.name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                    <a-form-item :label="$t('logistics.additional_options')">
                      <a-select
                        :allowClear="true"
                        style="max-width: 250px"
                        @change="record.quoteModel.trackFeeDetail = null"
                        v-model:value="record.quoteModel.accessorials"
                        mode="multiple"
                        :loading="DropdownList.loading"
                        :show-search="true"
                        option-filter-prop="search-key"
                      >
                        <a-select-option
                          v-for="(
                            item, index
                          ) in DropdownList.additionalOptionsList"
                          :value="item.id"
                          :key="index"
                          :title="item.name"
                          :search-key="item.name"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                      <div class="mt-3 ml-5">
                        <a-button
                          size="small"
                          :loading="DropdownList.loading"
                          @click="handleShowTrackFeeModal(record.quoteModel)"
                          :disabled="!isAllowEnquiry(record.quoteModel)"
                        >
                          {{ $t("logistics.inquiry_immediately") }}</a-button
                        >
                      </div>
                    </a-form-item>
                  </div>
                  <div v-else>
                    <a-form-item :label="$t('logistics.quote_type')">
                      {{ record.quoteModel.quoteTypeName }}
                    </a-form-item>
                    <a-form-item
                      :label="$t('logistics.service_level')"
                      v-if="record.quoteModel.needService"
                    >
                      {{ record.quoteModel.serviceLevelName }}
                    </a-form-item>
                    <a-form-item :label="$t('logistics.additional_options')">
                      <a-popover
                        placement="top"
                        v-if="record.quoteModel?.accessorialsName?.length > 0"
                      >
                        <template #content>
                          <div style="max-width: 350px">
                            <div
                              v-for="(item, index) in record.quoteModel
                                .accessorialsName"
                              :key="index"
                            >
                              {{ item }}
                              <span
                                v-if="
                                  index + 1 !=
                                  record.quoteModel.accessorialsName?.length
                                "
                                >,
                              </span>
                            </div>
                          </div>
                        </template>
                        <div
                          style="
                            max-width: 200px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          "
                        >
                          <span
                            v-for="(item, index) in record.quoteModel
                              .accessorialsName"
                            :key="index"
                          >
                            {{ item }}
                            <span
                              v-if="
                                index + 1 !=
                                record.quoteModel.accessorialsName?.length
                              "
                              >,</span
                            >
                          </span>
                        </div>
                      </a-popover>
                      <span v-else> - </span>
                    </a-form-item>
                  </div>
                </template>

                <template #quoteDetails="{ record }">
                  <div v-if="record.quoteModel.trackFeeDetail">
                    {{ $t("logistics.quote") }}:
                    {{ record.quoteModel.trackFeeDetail.carrierName }}
                    [
                    {{ record.quoteModel.trackFeeDetail?.currencySymbol }}
                    {{
                      $filters.amountToFixed2(
                        record.quoteModel?.trackFeeDetail?.totalCharge
                      )
                    }}
                    ,
                    <!-- {{record.quoteModel.trackFeeDetail.transitTime}} -->
                    {{
                      isNaN(
                        parseInt(record.quoteModel.trackFeeDetail.transitTime) +
                          ""
                      )
                        ? record.quoteModel.trackFeeDetail.transitTime
                        : parseInt(record.quoteModel.trackFeeDetail.transitTime)
                    }}

                    {{ $t("common.days") }}
                    ]
                    <div class="mt-3 ml-5">
                      <a-button
                        :loading="record.selectedLogisticsLoaidng"
                        v-if="!record.isSelected"
                        @click="hanldeGetFee(record)"
                        >{{ $t("common.confirm") }}</a-button
                      >
                    </div>
                  </div>
                  <span v-else>-</span>
                </template>
              </a-table>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>

      <!-- 选择的第三方面单 -->
      <div
        class="p-4"
        v-if="
          selectedAgentOrderLogisticsType ==
          agentOrderLogisticsTypeEnum.thirdparty
        "
      >
        <a-form>
          <a-form-item :label="$t('warehouse.channel')">
            <a-select
              v-model:value="otherSurface.deliveryType"
              style="max-width: 200px"
              optionFilterProp="search-key"
              show-search
              :placeholder="$t('common.please_select')"
            >
              <a-select-option
                v-for="item in deliveryWayEnum"
                :key="item"
                :title="$t($enumLangkey('deliveryWay', item))"
                :search-key="$t($enumLangkey('deliveryWay', item))"
                :value="item"
              >
                {{ $t($enumLangkey("deliveryWay", item)) }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :label="$t('warehouse.express_sheet')"
            style="min-height: 62px"
          >
            <CustomsUpload
              :functionModule="9"
              accept=".png,.jpg,.jpeg,.gif,.bmp,.pdf"
              v-model:fileUrl="otherSurface.surfaceUrl[0]"
            ></CustomsUpload>
          </a-form-item>
          <div class="text-warning">{{ $t("common.pdf_upload_hint") }}</div>
        </a-form>
      </div>

      <!-- 费用明细 -->
      <div
        class="mt-3"
        v-show="
          (selectedLogisticsScheme
          || selectedAgentOrderLogisticsType == agentOrderLogisticsTypeEnum.thirdparty
          || selectedAgentOrderLogisticsType == agentOrderLogisticsTypeEnum.destruction)
          && isShowFee
        "
      >
        <CFee
          :fees="calcTotal.fees"
          :totalFee="calcTotal.total"
          class="mt-5"
        ></CFee>
      </div>

      <!-- 上一步和确认支付 -->
      <a-row type="flex" justify="end" class="mt-5 mb-5">
        <a-col class="mr-5">
          <a-button @click="handleBackStep">{{
            $t("common.back_step")
          }}</a-button>
        </a-col>
        <a-col>
          <a-button
            class="mr-5"
            type="primary"
            :disabled="!isShowFee"
            :loading="payModal.loading"
            @click="handleShowOrderModal"
          >
            {{ $t("warehouse.confirm_and_pay") }}
          </a-button>
        </a-col>
      </a-row>

      <Payment
        ref="refPayment"
        :orderNo="cache?.relationOrderNo ?? ''"
        :currencyId="fee?.currencyId ?? ''"
        :unpaidSymbol="fee?.currencySymbol ?? ''"
        :unpaidSum="calcTotal?.total?.value ?? 0"
        :payModalTitleType="2"
        @handlePayFee="handleOrderPay"
        :loading="payModal.loading"
        v-model:visible="payModal.visible"
      ></Payment>

      <a-modal
        width="800px"
        v-model:visible="trackFeeModal.visible"
        :centered="true"
        :maskClosable="false"
        :ok-button-props="{ hidden: true }"
        :cancelText="$t('common.close')"
        :title="$t('logistics.select_quote')"
      >
        <a-table
          :columns="trackFeeColumns"
          :loading="DropdownList.loading"
          :data-source="trackFeeModal.list"
          :scroll="{ x: 500, y: 600 }"
          size="small"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :pagination="{
            defaultPageSize: trackFeeModal.pageData.currentPageSize,
            hideOnSinglePage: true,
            current: trackFeeModal.pageData.currentPageIndex,
          }"
          @change="
            (page) => {
              trackFeeModal.pageData.currentPageIndex = page.current;
            }
          "
        >
          <template #carrier>
            {{ $t("logistics.carrier") }}
          </template>
          <template #price>
            {{ $t("logistics.price") }}
          </template>
          <template #aging>
            {{ $t("logistics.aging") }}({{ $t("common.days") }})
          </template>

          <template #priceC="{ record }">
            {{ record.currencySymbol }}
            {{ $filters.amountToFixed2(record?.totalCharge) }}
          </template>

          <template #transitTime="{ record }">
            {{
              isNaN(parseInt(record.transitTime) + "")
                ? record.transitTime
                : parseInt(record.transitTime)
            }}
          </template>

          <template #select="{ record }">
            <div class="ant-form-inline">
              <a-button @click="handleSelectedFee(record)">{{
                $t("common.select")
              }}</a-button>
            </div>
          </template>
        </a-table>
      </a-modal>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref,computed } from "vue";
import {
  Row,
  Col,
  Steps,
  Tag,
  Typography,
  Popover,
  Table,
  Descriptions,
  Input,
  Tabs,
  Select,
  Button,
  Form,
  Modal,
  message,
  Card,
  Checkbox,
  Space,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage.vue";
import Payment from "@/views/components/Payment.vue";
import CFee from "@/views/components/CFee.vue";
import Address from "@/views/components/Address.vue";
import SearchProduct from "@/views/components/SearchProduct.vue";
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import CustomsUpload from "../../transport/components/Upload.vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { cloneDeep } from "lodash-es";
import { consignmentKeys } from "../../../consts/index";
import filters from "@/filters";
import { useTab } from "../../../hooks/tabs/index";
import { useSupplierHelper } from "../../../utils/supplierHelper";
import {
  getObjectSessionStorage,
  removeObjectSessionStorage,
} from "../../../utils/store";
import {
  getAddressByLanguageV2,
  cmCubicToM,
  gToKg,
} from "../../../utils/general";
import {
  getOrderFreightEstimate,
  getLogisticsFee,
  payOrderAndCreateOrder,
  getTrackLogisticsFee,
} from "../../../api/modules/consignment/index";
import {
  warehouseType,
  orderLogisticsFee as orderLogisticsFeeEnum,
  agentOrderLogisticsType as agentOrderLogisticsTypeEnum,
  deliveryWay as deliveryWayEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
  packMaterialType as packMaterialTypeEnum,
} from "../../../enum/enum.json";

export default {
  name: "storage_create_in_plan",
  components: {
    ACheckbox: Checkbox,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ATag: Tag,
    ACol: Col,
    ATable: Table,
    ATypographyText: Typography.Text,
    AInput: Input,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ACard: Card,
    ASpace: Space,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    APopover: Popover,
    Content,
    CSignSupported,
    CPlanNumberItem,
    CFee,
    Address,
    Payment,
    CImage,
    SearchProduct,
    CustomsUpload,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute, visitedRoutes } = useTab();
    const { getters } = useStore();
    const refSearchProduct = ref();
    const refPayment = ref(null);
    const supplierHelper = useSupplierHelper();

    const columns = [
      {
        dataIndex: "serialNumber ",
        slots: {
          title: "serialNumber",
        },
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
      },
        {
        dataIndex: "seSku",
        title: "SESKU",
      },
      {
        dataIndex: "packagingSize",
        slots: {
          title: "packagingSize",
          customRender: "packagingSizeCustom",
        },
      },
      {
        dataIndex: "weight",
        slots: {
          title: "weight",
        },
      },
      {
        dataIndex: "amount",
        slots: {
          title: "amount",
          customRender: "amountCustom",
        },
      },
    ];

    const columnsLogisticsScheme = [
      {
        dataIndex: "option",
        slots: {
          title: "option",
          customRender: "optionCustom",
        },
        width: 100,
      },
      {
        dataIndex: "logisticsScheme",
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
        width: 300,
      },
      {
        dataIndex: "logisticsWay", //物流类型
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
        width: 100,
      },
      {
        dataIndex: "deliveryWay", //交付方式
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
        width: 100,
      },
      {
        key: "daysMin",
        dataIndex: "referenceAging", //参考时效
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
        width: 160,
        sorter: (a, b) => a.daysMin - b.daysMin,
        sortDirections: ['ascend', 'descend'],
      },
      {
        key: "expressFee",
        dataIndex: "freights", //运费
        slots: {
          title: "freights",
          customRender: "freightsCustom", //平均单价和合计
        },
        width: 220,
        sorter: (a, b) => a.expressFee - b.expressFee,
        sortDirections: ['ascend', 'descend'],
      },
      {
        dataIndex: "details", //详情
        slots: {
          title: "details",
          customRender: "detailsCustom", //计费重、体积重系数：除600
        },
        width: 150,
      },
      {
        dataIndex: "optionalServices", //可选服务
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
        width: 200,
      },
    ];

    const columnsTruckScheme = [
      {
        title: () =>
          vueI18n.t("warehouse.logistics_scheme") +
          " / " +
          vueI18n.t("warehouse.logistics_code"),
        slots: {
          customRender: "logisticsSchemeCustom",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.logistics_type"),
        slots: {
          customRender: "logisticsWayCustom",
        },
        width: 80,
      },
      {
        title: () => vueI18n.t("warehouse.delivery_way"),
        slots: {
          customRender: "deliveryWayCustom",
        },
        width: 80,
      },
      {
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("logistics.quote_details"),
        slots: {
          customRender: "quoteDetails",
        },
        width: 100,
      },
    ];

    const state = reactive({
      orderLogisticsFeeEnum: orderLogisticsFeeEnum,
      agentOrderLogisticsTypeEnum,
      deliveryWayEnum,
      warehouseType: warehouseType,
      modalSearchValue: "",
      columnsLogisticsScheme: columnsLogisticsScheme,
      freightEstimateLoading: false,
      logisticsSchemeLoading: false,
      isShowFee: false,
      feeCalculationMethod: feeCalculationMethodEnum.template,
      selectedLogistics: null,
      cache: {
        relationOrderNo: "", //关联订单号
        selectedSourceSales: null, //销售来源
        recipients: "", //收件人
        contactNumber: "", //联系电话
        consignmentWarehouseNo: null, //发货仓
        selectedConsignmentWarehouse: null, //发货仓库Id
        addressData: {}, //运送地址

        producTableList: [],
        selectedPackagingInfo: {},
        packMaterialType: null,
        mergePackageData: null,
        transportCharacteristics: [],
      },

      isSearchLogistics: false,
      // logisticsSchemeList: [],
      truckList: [], //卡车物流方案
      list: [], //模板计算方案
      selectedLogisticsScheme: "",
      isShowVATInput: false,
      VAT: null, //税号

      isRemoteArea: null, //是否为偏远地址
      isCommercial: null, //是否为商业地址

      //费用明细
      fee: {
        warehouseFrees: [],
        transportFrees: [],
        totalAmount: 0, //费用总额
        oldTotalAmount: 0, //费用总额
        currencySymbol: "", //费用符号
        currencyId: "",
        fees: [],
        oldFees: null,
      },
      payModal: {
        visible: false,
        loading: false,
      },
      selectedAgentOrderLogisticsType: null,
      otherSurface: {
        deliveryType: null,
        surfaceUrl: [""],
      },
    });

    const getQuoteModel = (x) => {
      return {
        quoteTypeId: null,
        serviceLevel: null,
        accessorials: [],

        quoteTypeName: null,
        serviceLevelName: null,
        accessorialsName: [],

        logisticId: x?.logisticsBaseInfo?.id,

        needService: false,
        trackFeeDetail: null,
      };
    };

    const handleAcquisitionLogistics = () => {
      state.selectedLogistics = null;
      state.isShowFee = false;
      if (
        state.selectedAgentOrderLogisticsType ==
        agentOrderLogisticsTypeEnum.thirdparty
      ) {
        hanldeGetFee(state.otherSurface);
      } else {
        handleFreightEstimate();
      }
    };

    //物流方案
    const handleFreightEstimate = () => {
      state.columnsLogisticsScheme = columnsLogisticsScheme;
      state.isSearchLogistics = true;
      state.selectedLogisticsScheme = "";
      state.isShowVATInput = false;
      state.VAT = null;
      state.isRemoteArea = null;
      state.isCommercial = null;
      state.isShowFee = false;

      let products = [];
      state.cache.producTableList.forEach((x) => {
        products.push({
          ProductId: x.productId,
          count: x.amount,
        });
      });
      let pra = {
        warehouseId: state.cache.selectedConsignmentWarehouse,
        packMaterialType: state.cache.packMaterialType,
        packMaterialId: state.cache.selectedPackagingInfo.id ?? null,
        toAddr: state.cache.addressData,
        products: products,
      };
      state.freightEstimateLoading = true;
      state.logisticsSchemeLoading = true;
      getOrderFreightEstimate(pra)
        .then((res) => {
          state.list = [];
          state.truckList = [];
          let tempList = [];
          if (res.result && res.result.length > 0) {
            tempList = res.result.map((x, index) => {
              return {
                key: index,
                id: x.logisticsBaseInfo.id,
                scheme: x.logisticsBaseInfo.name,
                code: x.logisticsBaseInfo.code,
                descriptionItems: x.logisticsBaseInfo.descriptionItems,

                logisticsWay: x.logisticsBaseInfo.logisticsType,
                deliveryWay: x.logisticsBaseInfo.deliveryType,

                daysMin: x.logisticsBaseInfo.daysMin,
                daysMax: x.logisticsBaseInfo.daysMax,
                channelTimeType: x.logisticsBaseInfo.channelTimeType, //签收或自提 枚举

                averagePrice: x.logisticsBaseInfo.averagePrice, //平均费
                expressFee: x.logisticsBaseInfo.totalFeeWithoutServices, //合计费用
                currencySymbol: x.logisticsBaseInfo.currencySymbol, //货号符号

                chargeableWeight: gToKg(x.logisticsBaseInfo.chargeableWeight), //计费重
                volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam, //体积系数
                chargeableVolume: cmCubicToM(
                  x.logisticsBaseInfo.chargeableVolume
                ),
                logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,

                feeCalculationMethod: x.logisticsBaseInfo.feeCalculationMethod,
                logisticsRegionInfo: x.logisticsRegionInfo,
                isVatRequired: x.logisticsRegionInfo.isVatRequired,
                quoteModel: getQuoteModel(x),
                isSelected: false,
              };
            });
            state.list = tempList.filter(
              (x) => x.feeCalculationMethod != feeCalculationMethodEnum.truckApi
            );
            state.truckList = tempList.filter(
              (x) => x.feeCalculationMethod == feeCalculationMethodEnum.truckApi
            );

            //配置物流方案的选项卡
            if (state.list && state.list.length > 0) {
              state.feeCalculationMethod = feeCalculationMethodEnum.template;
            } else if (state.truckList && state.truckList.length > 0) {
              funcGetDropdownList();
              state.feeCalculationMethod = feeCalculationMethodEnum.truckApi;
            }
          } else {
            //nothing
          }
        })
        .finally(() => {
          state.freightEstimateLoading = false;
          state.logisticsSchemeLoading = false;
        });
    };

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const handleBackStep = async (record) => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "consignment_order_add_product" });
    };

    const getCacheData = () => {
      let cacheData = getObjectSessionStorage(
        consignmentKeys.createConsignmentOrderData
      );
      if (cacheData) {
        Object.assign(state.cache, cacheData);
        state.selectedAgentOrderLogisticsType =
          state.cache.selectedAgentOrderLogisticsType;
        if (
          state.selectedAgentOrderLogisticsType ==
          agentOrderLogisticsTypeEnum.thirdparty
        ) {
          hanldeGetFee(state.otherSurface);
        } else if (
          state.selectedAgentOrderLogisticsType ==
          agentOrderLogisticsTypeEnum.destruction
        ) {
          hanldeGetFee(state.otherSurface);
        } else {
          //nothing
        }
        _funcGetTransportCharacteristics();
      }
    };

    const _funcGetTransportCharacteristics = () => {
      let tempArry = [];
      state.cache.producTableList.forEach((x) => {
        tempArry.push.apply(tempArry, x.transportCharacteristics);
      });
      tempArry = [...new Set(tempArry)];
      Object.assign(state.cache.transportCharacteristics, tempArry);
    };

    const _getProductDatas = () => {
      let productDatas = [];
      state.cache.producTableList.forEach((x) => {
        productDatas.push({ productId: x.productId, outCount: x.amount });
      });
      return productDatas;
    };

    const hanldeGetFee = (record) => {
      if (record) {
        state.selectedLogisticsScheme = record.id;
      }

      let productDatas = _getProductDatas();
      let pra = {
        warehouseId: state.cache.selectedConsignmentWarehouse,
        packMaterialId: state.cache.selectedPackagingInfo.id,
        logisticsId: state.selectedLogisticsScheme,
        productDatas: productDatas,
        receiveAddressData: state.cache.addressData,
        logisticsType: state.selectedAgentOrderLogisticsType,
        packMaterialType: state.cache.packMaterialType,
      };
      if (record.feeCalculationMethod == feeCalculationMethodEnum.truckApi) {
        let pra2 = {
          quoteTypeId: record.quoteModel.quoteTypeId,
          serviceLevel: record.quoteModel.serviceLevel,
          accessorials: record.quoteModel.accessorials,
          quoteDetailId: record.quoteModel.trackFeeDetail.quoteDetailId,
        };
        pra = Object.assign(pra, { truckApiLogistics: pra2 });
      }

      record.selectedLogisticsLoaidng = true;
      state.payModal.loading = true;
      state.logisticsSchemeLoading = true;
      getLogisticsFee({ ...pra })
        .then((res) => {
          state.fee = {};
          if (!res) {
            return;
          }
          state.fee = {};

          state.fee.transportFrees = res.result.fees?.logisticsFee?.map((f) => {
            return {
              value: f?.amount,
              feeType: f?.feeType,
              symbol: res.result.fees.currencySymbol,
            };
          });
          state.fee.warehouseFees = res.result.fees?.operateFee?.map((t) => {
            return {
              value: t?.amount,
              feeType: t?.feeType,
              symbol: res.result.fees.currencySymbol,
            };
          });
          state.fee.fees = [
            ...state.fee.transportFrees,
            ...state.fee.warehouseFees,
          ];

          //用于计算属性calcTotal
          state.fee.oldFees= cloneDeep(state.fee.fees);

          state.fee.totalAmount = res.result.fees.totalAmount;
          state.fee.oldTotalAmount = res.result.fees.totalAmount;
          state.fee.currencySymbol = res.result.fees.currencySymbol;
          state.fee.currencyId = res.result.fees.currencyId;

          if (
            state.selectedAgentOrderLogisticsType ==
            agentOrderLogisticsTypeEnum.seLogistics
          ) {
            if (
              state.feeCalculationMethod == feeCalculationMethodEnum.template
            ) {
              state.selectedLogistics = feeCalculationMethodEnum.template;
              state.columnsLogisticsScheme =
                state.columnsLogisticsScheme.filter(
                  (x) => x.dataIndex != "option"
                );
              state.list = [];
              state.isShowVATInput = record?.isVatRequired;
              state.isRemoteArea =
                res.result.logistic.logisticsBaseInfo.isRemoteArea;
              state.isCommercial =
                res.result.logistic.logisticsBaseInfo.isCommercial;
              let r = [res.result.logistic];
              state.list = r?.map((x, index) => {
                return {
                  key: index,
                  id: x.logisticsBaseInfo.id,
                  scheme: x.logisticsBaseInfo.name,
                  code: x.logisticsBaseInfo.code,
                  descriptionItems: x.logisticsBaseInfo.descriptionItems,

                  logisticsWay: x.logisticsBaseInfo.logisticsType,
                  deliveryWay: x.logisticsBaseInfo.deliveryType,

                  daysMin: x.logisticsBaseInfo.daysMin,
                  daysMax: x.logisticsBaseInfo.daysMax,
                  channelTimeType: x.logisticsBaseInfo.channelTimeType, //签收或自提 枚举

                  averagePrice: x.logisticsBaseInfo.averagePrice, //平均费
                  expressFee: x.logisticsBaseInfo.totalFeeWithoutServices, //合计费用
                  currencySymbol: x.logisticsBaseInfo.currencySymbol, //货号符号

                  chargeableWeight: gToKg(x.logisticsBaseInfo.chargeableWeight), //计费重
                  volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam, //体积系数
                  chargeableVolume: cmCubicToM(
                    x.logisticsBaseInfo.chargeableVolume
                  ),
                  logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,

                  logisticsRegionInfo: x.logisticsRegionInfo,
                  isVatRequired: x.logisticsRegionInfo.isVatRequired,
                };
              });
            } else if (
              state.feeCalculationMethod == feeCalculationMethodEnum.truckApi
            ) {
              state.selectedLogistics = feeCalculationMethodEnum.truckApi;
              record.isSelected = true;
              try {
                let quotationType = DropdownList.quotationTypeList.find(
                  (x) => x.id == record.quoteModel.quoteTypeId
                );
                record.quoteModel.quoteTypeName = quotationType.name;
                if (quotationType.needService) {
                  record.quoteModel.serviceLevelName =
                    DropdownList.serviceLevelList.find(
                      (x) => x.id == record.quoteModel.quoteTypeId
                    )?.name;
                }

                for (
                  let index = 0;
                  index < record.quoteModel.accessorials.length;
                  index++
                ) {
                  let tempData = DropdownList.additionalOptionsList.find(
                    (x) => x.id == record.quoteModel.accessorials[index]
                  );
                  if (tempData && tempData.name) {
                    record.quoteModel.accessorialsName.push(tempData.name);
                  }
                }
              } catch (error) {}
              state.truckList = [record];
            }
          }

          state.isShowFee = true;
          state.logisticsSchemeLoading = false;
          record.selectedLogisticsLoaidng = false;
          state.payModal.loading = false;
        })
        .catch((e) => {
          state.isShowFee = false;
          state.logisticsSchemeLoading = false;
          record.selectedLogisticsLoaidng = false;
          state.payModal.loading = false;
        });
    };

    const handleOrderPay = () => {
      let otherSurface = null;
      if (
        state.selectedAgentOrderLogisticsType ==
        agentOrderLogisticsTypeEnum.thirdparty
      ) {
        otherSurface = {
          deliveryType: state.otherSurface.deliveryType,
          surfaceUrl: state.otherSurface.surfaceUrl,
        };
      }

      let pra = {
        payFee:filters.amountToFixed2(calcTotal?.value?.total?.value),
        relationOrderNo: state.cache.relationOrderNo,
        warehouseId: state.cache.selectedConsignmentWarehouse,
        saleSourceName: state.cache.selectedSourceSales,
        transportScheme: state.selectedLogisticsScheme,
        packMaterialId: state.cache.selectedPackagingInfo.id,
        logisticsId: state.selectedLogisticsScheme,
        productDatas: _getProductDatas(),
        receiveAddressData: state.cache.addressData,
        isChooseDdp: state.list[0]?.isChooseDdp,
        isChooseSign: state.list[0]?.signatureType > 0,
        signatureType:state.list[0]?.signatureType,
        vatCode: state.VAT,
        logisticsType: state.selectedAgentOrderLogisticsType,
        otherSurface: otherSurface,
        packMaterialType: state.cache.packMaterialType,
      };
      if (state.feeCalculationMethod == feeCalculationMethodEnum.truckApi) {
        let pra2 = {
          quoteTypeId: state.truckList[0].quoteModel.quoteTypeId,
          serviceLevel: state.truckList[0].quoteModel.serviceLevel,
          accessorials: state.truckList[0].quoteModel.accessorials,
          quoteDetailId:
            state.truckList[0].quoteModel.trackFeeDetail.quoteDetailId,
        };
        pra = Object.assign(pra, { truckApiLogistics: pra2 });
      }
      // if (state.selectedAgentOrderLogisticsType == agentOrderLogisticsTypeEnum.thirdparty) {
      //   pra.receiveAddressData = {
      //     receiptUserName: pra.receiveAddressData.receiptUserName,
      //     contactPhone: pra.receiveAddressData.contactPhone
      //   };
      // }

      state.payModal.loading = true;
      payOrderAndCreateOrder(pra)
        .then((res) => {
          message.success(vueI18n.t("common.succeed"));
          state.payModal.loading = false;
          state.payModal.visible = false;
          delVisitedRoute(router.currentRoute.value);
          router.push({ name: "consignment_order_list" });
        })
        .catch(() => {
          state.payModal.loading = false;
        });
    };

    const handleShowOrderModal = () => {
      if (
        state.selectedAgentOrderLogisticsType ==
          agentOrderLogisticsTypeEnum.seLogistics &&
        state.isShowVATInput &&
        (!state.VAT || state.VAT.trim().length == 0)
      ) {
        message.warning(vueI18n.t("common.p0_is_required", ["VAT"]));
        return;
      }

      if (
        state.selectedAgentOrderLogisticsType ==
        agentOrderLogisticsTypeEnum.thirdparty
      ) {
        if (!state.otherSurface.deliveryType) {
          message.warning(
            vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.channel")])
          );
          return;
        }

        if (
          !state.otherSurface.surfaceUrl ||
          !state.otherSurface.surfaceUrl[0]
        ) {
          message.warning(
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.express_sheet"),
            ])
          );
          return;
        }
      }

      state.payModal.visible = true;
      refPayment.value.handleShowPayOrder();
    };

    //-------------------卡车渠道 下拉列表及报价 begin--------------------------
    const DropdownList = reactive({
      quotationTypeList: [],
      serviceLevelList: [],
      additionalOptionsList: [],
      loading: false,
    });

    const handleChangeTabs = (key) => {
      if (key == feeCalculationMethodEnum.truckApi) {
        funcGetDropdownList();
      }
      state.feeCalculationMethod = key;
    };

    const funcGetDropdownList = () => {
      DropdownList.loading = true;
      Promise.all([
        supplierHelper.getQuoteTypeList(),
        supplierHelper.getServiceLevelList(),
        supplierHelper.getAdditionalOptionsList(),
      ])
        .then((result) => {
          if (result) {
            DropdownList.quotationTypeList = result[0];
            DropdownList.serviceLevelList = result[1];
            DropdownList.additionalOptionsList = result[2];
          }
          DropdownList.loading = false;
        })
        .catch(() => {
          DropdownList.loading = false;
        });
    };

    const isAllowEnquiry = (quoteModel) => {
      if (!quoteModel.quoteTypeId) {
        return false;
      } else {
        if (quoteModel.needService && !quoteModel.serviceLevel) {
          return false;
        }
        return true;
      }
    };

    const handleChangeQuoteType = (quoteModel) => {
      quoteModel.trackFeeDetail = null;
      quoteModel.serviceLevel = null;
      quoteModel.needService = DropdownList?.quotationTypeList.find(
        (x) => x.id == quoteModel.quoteTypeId
      ).needService;
    };

    const hanldeGetTrackLogisticsFee = (quoteModel) => {
      var cacheKey = getCacheKey(quoteModel);
      if (quotesCacheObj[cacheKey]) {
        trackFeeModal.list = quotesCacheObj[cacheKey];
        return;
      }

      trackFeeModal.list = [];
      let productDatas = _getProductDatas();
      let pra = {
        warehouseId: state.cache.selectedConsignmentWarehouse,
        packMaterialId: state.cache.selectedPackagingInfo.id,
        productDatas: productDatas,
        receiveAddressData: state.cache.addressData,
        logisticsType: state.selectedAgentOrderLogisticsType,
        relationOrderNo: state.cache.relationOrderNo,
        logisticsId: quoteModel.logisticId,
      };

      DropdownList.loading = true;
      getTrackLogisticsFee({ ...pra, truckApiLogistics: quoteModel })
        .then(({ result }) => {
          if (result) {
            trackFeeModal.list = result;
            quotesCacheObj[cacheKey] = result;
          } else {
            trackFeeModal.quoteModel.currencySymbol = null;
            trackFeeModal.quoteModel.totalCharge = null;
          }
          DropdownList.loading = false;
        })
        .catch(() => {
          DropdownList.loading = false;
        });
    };

    const trackFeeModal = reactive({
      visible: false,
      list: [],
      quoteModel: null,
      pageData: {
        currentPageIndex: 1,
        currentPageSize: 10,
      },
    });

    var quotesCacheObj = {};

    var getCacheKey = (paras) => {
      return (
        paras.quoteTypeId +
        ";" +
        paras.serviceLevel +
        ":" +
        paras.logisticId +
        "," +
        (paras.accessorials || []).sort().join("-")
      );
    };

    const handleShowTrackFeeModal = (quoteModel) => {
      trackFeeModal.visible = true;
      trackFeeModal.quoteModel = quoteModel;
      trackFeeModal.pageData.currentPageIndex = 1;
      hanldeGetTrackLogisticsFee(quoteModel);
    };

    const handleSelectedFee = (record) => {
      trackFeeModal.quoteModel.trackFeeDetail = record;
      trackFeeModal.visible = false;
    };

    const trackFeeColumns = [
      {
        dataIndex: "carrierName",
        slots: {
          title: "carrier",
        },
        sorter: (a, b) => {
          return (a.carrierName || "").localeCompare(b.carrierName);
        },
        width: 250,
      },
      {
        title: () => vueI18n.t("logistics.price"),
        slots: {
          customRender: "priceC",
        },
        sorter: (a, b) => {
          return a.totalCharge - b.totalCharge;
        },
        width: 150,
      },
      {
        dataIndex: "transitTime",
        slots: {
          title: "aging",
          customRender: "transitTime",
        },
        sorter: (a, b) => {
          return a.transitTime - b.transitTime;
        },
        width: 100,
      },
      {
        title: "",
        slots: {
          customRender: "select",
        },
        fixed: "right",
        width: 100,
      },
    ];

    //-------------------卡车渠道 下拉列表及报价 end--------------------------

    //费用计算
    const calcTotal = computed(() => {
      //基础费用 详情数组(费用类型,这里已包含运输费和仓库费)
      let fees = cloneDeep(state.fee.oldFees);
      //基础费用 金额
      let total = {
        value:state.fee.oldTotalAmount,
        symbol:state.fee.currencySymbol,
      };

      //计算详情数组:DDP和签名签收
      if (
        state.selectedLogisticsScheme &&
        state.selectedAgentOrderLogisticsType ==
            agentOrderLogisticsTypeEnum.seLogistics&&
        state.feeCalculationMethod == feeCalculationMethodEnum.template
      ) {
        if (state.list?.length > 0) {
          //ddp
          if (state.list[0]?.isChooseDdp) {
            fees.push({
              symbol: total.symbol,
              value: state.list[0]?.logisticsRegionInfo?.ddpFee,
              feeType: state.list[0]?.logisticsRegionInfo?.ddpFeeType,
            });

            total.value =
              total.value + state.list[0]?.logisticsRegionInfo?.ddpFee ?? 0;
          }

          //签名签收
          if (state.list[0]?.signatureType) {
            let signature =
              state.list[0]?.logisticsRegionInfo?.signFeeDtos?.find(
                (x) => x.signatureType == state.list[0]?.signatureType
              );

            fees.push({
              symbol: total.symbol,
              value: signature?.fee,
              feeType: state.list[0]?.logisticsRegionInfo?.signFeeType,
            });

            total.value = total.value + signature?.fee ?? 0;
          }
        }
      }

      return {
        fees: fees,
        total: total,
      };
    });


    onMounted(async () => {
      getCacheData();
    });

    onBeforeRouteLeave((to, from, next) => {
      const orderRoutes = [
        "/consignment/order/create",
        "/consignment/order/addproduct",
        "/consignment/order/selectlogistics",
      ];
      if (!orderRoutes.includes(to.path)) {
        let tabsHasOrderRoute =
          visitedRoutes.value.findIndex((x) => orderRoutes.includes(x.path)) >
          -1;
        if (!tabsHasOrderRoute) {
          removeObjectSessionStorage(
            consignmentKeys.createConsignmentOrderData
          );
        }
      }
      next();
    });

    return {
      columns,
      ...toRefs(state),
      refPayment,
      refSearchProduct,
      handleBackStep,
      getAddress,
      handleFreightEstimate,
      hanldeGetFee,
      handleOrderPay,
      handleShowOrderModal,
      handleAcquisitionLogistics,

      DropdownList,
      feeCalculationMethodEnum,
      packMaterialTypeEnum,
      columnsTruckScheme,
      isAllowEnquiry,
      handleChangeQuoteType,
      hanldeGetTrackLogisticsFee,
      trackFeeModal,
      trackFeeColumns,
      handleShowTrackFeeModal,
      handleSelectedFee,
      handleChangeTabs,

      calcTotal
    };
  },
};
</script>

<style lang="less" scoped>
.input-error-border,
.input-error-border input {
  border-color: #ff4d4f;
}
</style>
